// Variables & Mixins
$border-color: #888;
$white: #fff;

@mixin vh-center() {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.wrapper {
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;

  @media ( min-width: 768px ) {
    max-width: 635px;
  }
}

.wp-bingo {
  &.layout-simple {
    margin: 0;
    padding: 0;
  }

  &__header {
    border: solid $border-color;
    border-width: 0 2px 0 0;
    display: grid;
    grid-template-columns: repeat(5, 20%);

    > div {
      @include vh-center;
      border: solid $border-color;
      border-width: 2px 0 0 2px;
      min-height: 100px;
      padding: 10px;
    }
  }

  &__wrapper {
    border: solid $border-color;
    border-width: 0 2px 2px 0;
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }

  &__item {
    @include vh-center;
    background-color: #111;
    border: solid $border-color;
    border-width: 2px 0 0 2px;
    color: $white;
    cursor: pointer;
    min-height: 100px;
    padding: 10px;

    &.active {
      background-color: #00f;
    }

    &:hover {
      background-color: $border-color;
    }
  }
}

#confetti { // stylelint-disable-line selector-max-id
  left: 0;
  position: absolute;
  top: 0;
}
